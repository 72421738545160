<template>
  <div>
    <!--===// Start: Slider
    =================================-->
    <span v-if="loading">
      <Preloader />
    </span>
    <div v-else>
      <slider :sliders="sliders" :loading="loading" />
      <info />
      <service />
      <design />
      <price />

      <project />
      <team />
      <testimonial />
      <fun />
      <post />
      <sponsor />
      <gallery />
    </div>
    <!--===// Start: Footer
    =================================-->
  </div>
</template>

<script>
// @ is an alias to /src
import Preloader from "../components/Preloader.vue";
import HelloWorld from "@/components/HelloWorld.vue";
import Testimonial from "../components/Testimonial.vue";
import Gallery from "../components/Gallery.vue";
import Slider from "../components/Slider.vue";
import Service from "../components/Service.vue";
import Design from "../components/Design.vue";
import Price from "../components/Price.vue";
import Project from "../components/Project.vue";
import Team from "../components/Team.vue";
import Fun from "../components/Fun.vue";
import Post from "../components/Post.vue";
import Sponsor from "../components/Sponsor.vue";
import Info from "../components/Info.vue";
import "../../public/wp-content/uploads/maxmegamenu/style772c.css";
export default {
  name: "Home",
  components: {
    HelloWorld,
    Testimonial,
    Gallery,
    Slider,
    Service,
    Design,
    Price,
    Project,
    Team,
    Fun,
    Post,
    Sponsor,
    Info,
    Preloader,
  },
  data() {
    return { scale: 0.6, sliders: null, loading: true, props: "test" };
  },
  methods: {
    getsliders() {
      this.loading = true;
      this.$axios.get("allslider").then((res) => {
        this.sliders = res.data.data;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getsliders();
  },
};
</script>
