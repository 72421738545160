<template>
  <div id="app">
    <body
      class="home-page bp-nouveau home page-template page-template-templates page-template-template-homepage page-template-templatestemplate-homepage-php page page-id-764 wp-custom-logo theme-cozipress-pro woocommerce-no-js non-logged-in header-transparent homepage-1 wide footer-parallax elementor-default elementor-kit-11 no-js"
    >
      <div id="page" class="site">
        <a class="skip-link screen-reader-text" href="#content"
          >Skip to content</a
        >
        <!--===// Start: Main Header
    =================================-->
        <Header />
        <!-- End: Main Header
    =================================-->
        <div id="content" class="cozipress-content text-left">
          <router-view />
        </div>
        <Footer />
        <!-- Scrolling Up -->

        <button
          type="button"
          class="scrollingUp scrolling-btn"
          aria-label="scrollingUp"
          v-show="scY > 300"
          :class="{ scrollshow: scY > 300 }"
          @click="toTop"
        >
          <i class="fa fa-angle-up"></i>
        </button>
      </div>
      <div class="floating-menu float-menu-1 fm-ready">
        <ul
          class="fm-bar fm-right fm-connected fm-white-icon fm-white-icon-over fm-white-label-text fm-side-space fm-button-space fm-css-anim"
          style="right: 0px; top: 50%"
        >
          <li class="fm-item-1-0" @mouseover="select" @mouseleave="unselect">
            <router-link to="contact" target="_self"
              ><div class="fm-icon"><i class="fas fa-envelope"></i></div>
              <div class="fm-label">Contact</div></router-link
            >
          </li>
          <li class="fm-item-1-1" @mouseover="select" @mouseleave="unselect">
            <router-link to="simulation-de-la-remuneration" target="_self"
              ><div class="fm-icon"><i class="fas fa-calculator"></i></div>
              <div class="fm-label">Simulation</div></router-link
            >
          </li>
          <li class="fm-item-1-2" @mouseover="select" @mouseleave="unselect">
            <router-link class="selector" to="etre-recontacte" target="_self"
              ><div class="fm-icon"><i class="fas fa-phone"></i></div>
              <div class="fm-label">Etre recontacté</div></router-link
            >
          </li>
        </ul>
      </div>
    </body>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      scTimer: 0,
      scY: 0,
      selected: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    select() {
      event.currentTarget.querySelector(".fm-label").classList.add("fm-show");
    },
    unselect() {
      document.querySelector(".fm-show").classList.remove("fm-show");
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.scrollshow {
  visibility: visible;
  opacity: 1;
}
</style>
