<template>
  <div>
    <section id="slider-section" class="slider-section">
      <carousel
        :items="1"
        class="home-slider owl-carousel owl-theme"
        :nav="true"
        :loop="true"
        v-if="!loading"
        :navElement="'button'"
        :navText="[
          '<i class=&quot;fa fa-angle-left&quot;></i>',
          '<i class=&quot;fa fa-angle-right&quot;></i>',
        ]"
        :autoplay="true"
      >
        <div class="item" v-for="item in sliders" :key="item.id">
          <img
            :src="item.image"
            data-img-url="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/slider/img02.jpg"
            alt="Portage Salariale"
            title="Portage Salariale"
          />
          <div class="main-slider">
            <div class="main-table">
              <div class="main-table-cell">
                <div class="container">
                  <div class="main-content text-left">
                    <h4 data-animation="fadeInUp" data-delay="150ms">
                      <i class="fa fa-pencil"></i> Portage Salariale
                    </h4>

                    <h1 data-animation="fadeInUp" data-delay="200ms">
                      {{ item.title }}
                    </h1>

                    <p data-animation="fadeInUp" data-delay="500ms">
                      {{ item.description }}
                    </p>

                    <router-link
                      title="Rejoindre Portalite"
                      to="contact"
                      data-animation="fadeInUp"
                      data-delay="800ms"
                      class="btn btn-primary btn-like-icon"
                      >Rejoingnez PORTALITE
                      <span class="bticn"
                        ><i class="fa fa-arrow-right"></i></span
                    ></router-link>
                    <router-link
                      title="Je suis consultant"
                      to="simulation-de-la-remuneration"
                      data-animation="fadeInUp"
                      data-delay="800ms"
                      class="btn btn-border-white btn-like-icon"
                      >Simuler mon salaire
                      <span class="bticn"
                        ><i class="fa fa-arrow-right"></i></span
                    ></router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </carousel>
    </section>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  components: { carousel },
  props: ["sliders", "loading"],
  // data() {
  //   return { sliders: [], loading: true };
  // },
  // methods: {
  //   getsliders() {
  //     this.loading = true;
  //     this.$axios.get("allslider").then((res) => {
  //       this.sliders = res.data.data;
  //       this.loading = false;
  //     });
  //   },
  // },
  mounted() {
    //this.getsliders();
    console.log(this.sliders);
  },
};
</script>

<style>
.owl-theme .owl-nav [class*="owl-"]:hover {
  background: transparent !important;
  color: transparent;
  text-decoration: none;
}
</style>
