import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/simulation-de-la-remuneration",
    name: "SimulationDeLaRemuneration",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/SimulationDeLaRemuneration.vue"
      );
    },
  },
  {
    path: "/les-avantages-du-portage-salarial",
    name: "Lesvantagesduportagesalarialconsultants",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/Lesvantagesduportagesalarialconsultants.vue"
      );
    },
  },
  {
    path: "/le-concept-du-portage-salarial",
    name: "Leconceptduportagesalarial",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/Leconceptduportagesalarial.vue"
      );
    },
  },
  {
    path: "/parrainage",
    name: "Parrainage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/Parrainage.vue");
    },
  },
  {
    path: "/entreprises",
    name: "Entreprise",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/Entreprise.vue");
    },
  },
  {
    path: "/je-cherche-un-consultant",
    name: "Jechercheunconsultant",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/Jechercheunconsultant.vue"
      );
    },
  },
  {
    path: "/les-avantages-du-portage-salarial-entrerprises",
    name: "Lesavantagesduportagesalarialentrerprises",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/Lesavantagesduportagesalarialentrerprises.vue"
      );
    },
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/Contact.vue");
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/About.vue");
    },
  },
  {
    path: "/nos-services",
    name: "Nosservices",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/Nosservice.vue");
    },
  },
  {
    path: "/service/:item",
    name: "service",

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/page.vue");
    },
  },

  {
    path: "/etre-recontacte",
    name: "Etrerecontacte",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/Etrerecontacte.vue"
      );
    },
  },
  {
    path: "/:item",
    name: "navigation",

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/Pagenavigation.vue"
      );
    },
  },
];
const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
});

export default router;
