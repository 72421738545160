<template>
  <section id="info-section" class="info-section">
    <div class="container">
      <div class="row">
        <div class="col-12 wow fadeInUp">
          <div class="row g-4 info-wrapper">
            <div
              class="col-xl-3 col-lg-6 col-md-6 col-12"
              v-for="item in infos"
              :key="item.id"
            >
              <aside class="widget widget-contact">
                <div class="contact-area">
                  <div class="contact-icon">
                    <div class="contact-corn" v-html="item.fa_icon"></div>
                  </div>
                  <div class="contact-info">
                    <h6 class="title">
                      <a>{{ item.title }}</a>
                    </h6>

                    <p class="text">
                      {{ item.text }}
                    </p>
                  </div>
                </div>
                <div class="overlay-box">
                  <div class="overlay-inner">
                    <div class="contact-area">
                      <div class="contact-icon">
                        <div class="contact-corn" v-html="item.fa_icon"></div>
                      </div>

                      <div class="contact-info">
                        <h6 class="title">
                          <a>{{ item.title }}</a>
                        </h6>

                        <p class="text">
                          {{ item.text }}
                        </p>

                        <a href="#" class="arrow"
                          ><i class="fa fa-arrow-right"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
            <!-- <div class="col-lg-3 col-md-6 col-12">
              <aside class="widget widget-contact">
                <div class="contact-area">
                  <div class="contact-icon">
                    <div class="contact-corn">
                      <i class="fa fa-area-chart"></i>
                    </div>
                  </div>
                  <div class="contact-info">
                    <h6 class="title"><a href="#">Compétitivité </a></h6>

                    <p class="text">
                      Les frais de gestion ne sont pas un critère pour choisir ,
                      c&#039;est le revenu net qui importe pour éviter les frais
                      cachés.
                    </p>
                  </div>
                </div>
                <div class="overlay-box">
                  <div class="overlay-inner">
                    <div class="contact-area">
                      <div class="contact-icon">
                        <div class="contact-corn">
                          <i class="fa fa-area-chart"></i>
                        </div>
                      </div>

                      <div class="contact-info">
                        <h6 class="title"><a href="#">Compétitivité </a></h6>

                        <p class="text">
                          Les frais de gestion ne sont pas un critère pour
                          choisir , c&#039;est le revenu net qui importe pour
                          éviter les frais cachés.
                        </p>

                        <a href="#" class="arrow"
                          ><i class="fa fa-arrow-right"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <aside class="widget widget-contact">
                <div class="contact-area">
                  <div class="contact-icon">
                    <div class="contact-corn">
                      <i class="fa fa-user"></i>
                    </div>
                  </div>
                  <div class="contact-info">
                    <h6 class="title"><a href="#">Offre de missions</a></h6>

                    <p class="text">
                      Nous vous mettons en relation avec les bons contacts pour
                      trouver facilement des missions pour votre prospection
                    </p>
                  </div>
                </div>
                <div class="overlay-box">
                  <div class="overlay-inner">
                    <div class="contact-area">
                      <div class="contact-icon">
                        <div class="contact-corn">
                          <i class="fa fa-user"></i>
                        </div>
                      </div>

                      <div class="contact-info">
                        <h6 class="title"><a href="#">Offre de missions</a></h6>

                        <p class="text">
                          Nous vous mettons en relation avec les bons contacts
                          pour trouver facilement des missions pour votre
                          prospection
                        </p>

                        <a href="#" class="arrow"
                          ><i class="fa fa-arrow-right"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <aside class="widget widget-contact">
                <div class="contact-area">
                  <div class="contact-icon">
                    <div class="contact-corn">
                      <i class="fa fa-dollar"></i>
                    </div>
                  </div>
                  <div class="contact-info">
                    <h6 class="title"><a href="#">Business</a></h6>

                    <p class="text">
                      Ne vous limitez pas juste à être porté mais osez d’être un
                      ambassadeur ou apporteur d&#039;affaire et chez nous la
                      récompense est généreuse
                    </p>
                  </div>
                </div>
                <div class="overlay-box">
                  <div class="overlay-inner">
                    <div class="contact-area">
                      <div class="contact-icon">
                        <div class="contact-corn">
                          <i class="fa fa-dollar"></i>
                        </div>
                      </div>

                      <div class="contact-info">
                        <h6 class="title"><a href="#">Business</a></h6>

                        <p class="text">
                          Ne vous limitez pas juste à être porté mais osez
                          d’être un ambassadeur ou apporteur d&#039;affaire et
                          chez nous la récompense est généreuse
                        </p>

                        <a href="#" class="arrow"
                          ><i class="fa fa-arrow-right"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return { infos: null, loading: true };
  },
  methods: {
    getInfo() {
      this.loading = true;
      this.axios.get("allinfo").then((res) => {
        this.infos = res.data.data;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getInfo();
  },
};
</script>

<style></style>
