<template>
  <section
    id="design-section"
    class="design-home design-section st-py-default bg-primary-light shapes-section"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12 mx-lg-auto mb-5 text-center">
          <div class="heading-default wow fadeInUp">
            <h2>Nous <span class="text-primary">en trois mots</span></h2>
          </div>
        </div>
      </div>
      <div class="row row-cols-lg-2 row-cols-1 g-lg-0 g-5 mt-1">
        <div class="col wow fadeInLeft">
          <div class="tilter">
            <div class="design-img tilter__figure">
              <img
                src="wp-content/themes/cozipress-pro/assets/images/about/design-img.jpg"
                class="img-fluid"
                alt="Cozipress"
              />

              <div class="tilter__deco--lines"></div>
            </div>
          </div>
        </div>
        <div class="col wow fadeInRight">
          <div class="row row-cols-1 g-4 p-lg-5 p-0 design-wrp">
            <div class="col">
              <div class="design-item">
                <div class="design-icon">
                  <div class="design-corn">
                    <i class="fa fa-bar-chart txt-pink"></i>
                  </div>
                </div>
                <div class="design-content">
                  <h4 class="design-title">
                    <a href="#">Notre Mission</a>
                  </h4>

                  <p>
                    Être le plus proche possible des attentes de nos consultants
                    portés et gagner leur confiance
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="design-item">
                <div class="design-icon">
                  <div class="design-corn">
                    <i class="fa fa-search txt-pink"></i>
                  </div>
                </div>
                <div class="design-content">
                  <h4 class="design-title">
                    <a href="#">Notre Vision</a>
                  </h4>

                  <p>
                    Fédérer un réseau de consultants qui s&#039;entraident pour
                    le sucés de chacun
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="design-item">
                <div class="design-icon">
                  <div class="design-corn">
                    <i class="fa fa-umbrella txt-pink"></i>
                  </div>
                </div>
                <div class="design-content">
                  <h4 class="design-title">
                    <a href="#">Notre équipe</a>
                  </h4>

                  <p>
                    En mode petite structure pour être très simple et efficace
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lg-shape5">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape5.png"
        alt="image"
      />
    </div>
    <div class="lg-shape6">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape6.png"
        alt="image"
      />
    </div>
    <div class="lg-shape7">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape7.png"
        alt="image"
      />
    </div>
    <div class="lg-shape8">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape8.png"
        alt="image"
      />
    </div>
    <div class="lg-shape9">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape9.png"
        alt="image"
      />
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
