<template>
  <section
    id="price-section"
    class="home-pricing price-section st-py-default shapes-section"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12 mx-lg-auto mb-5 text-center">
          <div class="heading-default wow fadeInUp">
            <h2>Notre<span class="text-primary"> Offre </span></h2>

            <p>Pack Portalite All in one</p>
          </div>
        </div>
      </div>
      <div class="st-filter-wrapper-1">
        <div class="st-filter-swipe mt-5">
          <div class="st-tab-filter tab-swipe-filter text-center">
            <a
              @click.prevent="show = !show"
              :class="{ 'is-active active': show }"
              data-filter=".decouvrir"
              >Découvrir</a
            >
            <span
              class="indicator"
              v-show="show"
              style="left: 1px; width: 171.563px"
            ></span>
          </div>
        </div>
        <transition name="fade">
          <div
            id="st-filter-init-1"
            v-if="show"
            class="row row-cols-1 row-cols-lg-3 row-cols-md-2 st-filter-init pricing-init g-4 wow"
          >
            <div class="col st-filter-item decouvrir">
              <div class="pricing-item">
                <div class="pricing-rate">
                  <h3>Frais de gestion 4%</h3>

                  <p class="mb-0 mt-2">CA &gt;=15000</p>
                </div>
                <div class="pricing-inner">
                  <a
                    href="etre-recontacte/index.html"
                    class="btn btn-secondary btn-like-icon mb-3"
                    >Etre rappelé
                    <span class="bticn"><i class="fa fa-phone"></i></span
                  ></a>

                  <div class="pricing-list">
                    <ul>
                      <li>
                        Gestion administrative , financière , commerciale et
                        juridique
                      </li>
                      <li>
                        Avance totale des salaires et frais professionnels
                      </li>

                      <li>Optimisation des revenus</li>

                      <li>
                        Accés Intranet avec Tableau de board pour monitorer vos
                        comptes
                      </li>
                      <li>Garantie financière</li>

                      <li>
                        Assurance responsabilité civile et professionnelle
                      </li>

                      <li>Mutuelle d&#039;entreprise avec prix aventagieux</li>

                      <li>Offre de missions</li>

                      <li>Accompagnement personalisé</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="col st-filter-item decouvrir">
              <div class="pricing-item">
                <div class="pricing-rate">
                  <h3>Frais de Gestion 6 %</h3>

                  <p class="mb-0 mt-2">10000=&lt;CA &lt;15000</p>
                </div>
                <div class="pricing-inner">
                  <a
                    href="etre-recontacte/index.html"
                    class="btn btn-secondary btn-like-icon mb-3"
                    >Etre rappelé
                    <span class="bticn"><i class="fa fa-phone"></i></span
                  ></a>

                  <div class="pricing-list">
                    <ul>
                      <li>
                        Gestion administrative , financière , commerciale et
                        juridique
                      </li>
                      <li>
                        Avance totale des salaires et frais professionnels
                      </li>

                      <li>Optimisation des revenus</li>

                      <li>
                        Accés Intranet avec Tableau de board pour monitorer vos
                        comptes
                      </li>
                      <li>Garantie financière</li>

                      <li>
                        Assurance responsabilité civile et professionnelle
                      </li>

                      <li>Mutuelle d&#039;entreprise avec prix aventagieux</li>

                      <li>Offre de missions</li>

                      <li>Accompagnement personalisé</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="col st-filter-item decouvrir">
              <div class="pricing-item">
                <div class="pricing-rate">
                  <h3>Frais de gestion 8%</h3>

                  <p class="mb-0 mt-2">CA &lt;10000</p>
                </div>
                <div class="pricing-inner">
                  <a
                    href="etre-recontacte/index.html"
                    class="btn btn-secondary btn-like-icon mb-3"
                    >Etre rappelé
                    <span class="bticn"><i class="fa fa-phone"></i></span
                  ></a>

                  <div class="pricing-list">
                    <ul>
                      <li>
                        Gestion administrative , financière , commerciale et
                        juridique
                      </li>
                      <li>
                        Avance totale des salaires et frais professionnels
                      </li>

                      <li>Optimisation des revenus</li>

                      <li>
                        Accés Intranet avec Tableau de board pour monitorer vos
                        comptes
                      </li>
                      <li>Garantie financière</li>

                      <li>
                        Assurance responsabilité civile et professionnelle
                      </li>

                      <li>Mutuelle d&#039;entreprise avec prix aventagieux</li>

                      <li>Offre de missions</li>

                      <li>Accompagnement personalisé</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="lg-shape12">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape12.png"
        alt="image"
      />
    </div>
    <div class="lg-shape13">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape13.png"
        alt="image"
      />
    </div>
    <div class="lg-shape14">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape14.png"
        alt="image"
      />
    </div>
    <div class="lg-shape15">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape15.png"
        alt="image"
      />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
