<template>
  <section
    id="funfact-section"
    class="funfact-section st-py-default"
    style="
      background: url('wp-content/themes/cozipress-pro/assets/images/funfact/dotted_image.png')
        fixed no-repeat center top / cover rgb(255 255 255 / 0.25);
      background-blend-mode: multiply;
    "
  >
    <div class="container">
      <div
        class="row row-cols-1 row-cols-lg-3 row-cols-md-2 g-4 wow fadeInUp fun-contents"
        v-if="!loading"
      >
        <div class="col" v-for="item in data" :key="item.id">
          <div class="funfact-single">
            <div class="funfact-icon" v-html="item.fa_icon"></div>
            <div class="funfact-content">
              <h2>
                <span class="counter">{{ item.number }}</span>
              </h2>

              <p>{{ item.data }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return { data: [], loading: true };
  },
  methods: {
    getdata() {
      this.loading = true;
      this.$axios.get("/alldata").then((res) => {
        this.data = res.data.data;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getdata();
  },
};
</script>

<style></style>
