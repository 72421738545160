<template>
  <footer id="footer-section" class="footer-section main-footer" style="">
    <div class="footer-main st-pt-default">
      <div class="container">
        <div class="row row-cols-1 row-cols-lg-4 row-cols-md-2 g-5"></div>
      </div>
    </div>

    <div class="footer-copyright">
      <div class="container">
        <div class="row align-items-center gy-lg-0 gy-4">
          <div
            class="col-lg-12 col-md-12 col-12 text-lg-center text-md-center text-center"
          >
            <div class="widget-left text-lg-left text-md-left text-center">
              <aside class="widget widget-contact" v-if="!loading">
                <div id="aside" style="display: flex">
                  <div class="contact-area" v-if="coordinate.phone">
                    <div class="contact-icon">
                      <div class="contact-corn">
                        <i class="fa fa-phone"></i>
                      </div>
                    </div>

                    <div class="contact-info">
                      <h6 class="title"></h6>
                      <p class="text">
                        <a :href="`tel:${coordinate.phone}`"
                          >(+33) {{ coordinate.phone }}</a
                        >
                      </p>
                    </div>
                  </div>
                  <div class="contact-area" v-if="coordinate.email">
                    <div class="contact-icon">
                      <div class="contact-corn">
                        <i class="fa fa-envelope-o"></i>
                      </div>
                    </div>

                    <div class="contact-info">
                      <h6 class="title">Contactez-Nous</h6>
                      <p class="text">
                        <a :href="'email:' + coordinate.email">{{
                          coordinate.email
                        }}</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="contact-area" v-if="coordinate.adress">
                  <div class="contact-icon">
                    <div class="contact-corn">
                      <i class="fa fa-address-card-o"></i>
                    </div>
                  </div>

                  <div class="contact-info">
                    <h6 class="title">Contactez-Nous</h6>
                    <p class="text">
                      <a>{{ coordinate.adress }}</a>
                    </p>
                  </div>
                </div>
              </aside>
            </div>
          </div>
          <div
            class="col-lg-12 col-md-12 col-12 text-lg-center text-md-center text-center"
          >
            <div class="copyright-text">
              Copyright © 2022 Portalite | Portage Salarial
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      coordinate: null,

      loading: true,
    };
  },
  methods: {
    getCoordinate() {
      this.loading = true;
      this.$axios.get("allcoordinate").then((res) => {
        this.coordinate = res.data.data[0];
        this.loading = false;
        console.log(this.coordinate);
      });
    },
  },
  mounted() {
    console.log("test footer");
    this.getCoordinate();
  },
};
</script>

<style>
#footer-section {
  background: url("/wp-content/themes/cozipress-pro/assets/images/bg/footer_bg.jpg")
    no-repeat scroll center center / cover rgb(0 0 0 / 0.75);
  background-blend-mode: multiply;
}
@media only screen and (max-width: 800px) {
  #aside {
    display: block !important;
  }
}
</style>
