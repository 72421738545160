<template>
  <section
    id="sponsors-section"
    class="sponsors-section st-py-default sponsor-home"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12 mx-lg-auto mb-5 text-center">
          <div class="heading-default wow fadeInUp">
            <h2>Nos<span class="text-primary">Partenaires</span></h2>
          </div>
        </div>
      </div>
      <div
        class="row row-cols-1 row-cols-lg-5 row-cols-md-2 sponsors-area g-0 wow fadeInUp"
      >
        <carousel
          class="owl-carousel owl-theme"
          :autoplay="true"
          :nav="false"
          :loop="true"
          :items="4"
          v-if="!loading"
        >
          <article
            class="sponsors-items"
            v-for="item in parteners"
            :key="item.id"
          >
            <div class="sponsors-img">
              <a :href="item.href"><img :src="item.image" /></a>
            </div>
          </article>
        </carousel>
      </div>
    </div>
  </section>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  components: { carousel },
  data() {
    return { parteners: [], loading: true };
  },
  methods: {
    getPartener() {
      this.loading = true;
      this.$axios.get("allpartener").then((res) => {
        this.parteners = res.data.data;
        console.log(this.parteners);
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getPartener();
  },
};
</script>

<style scoped>
.sponsors-img {
  height: 150px;
  width: 100%;
}
.sponsors-img a img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: inline-block;
}
</style>
