<template>
  <section
    id="service-section"
    class="service-section service-home st-py-default shapes-section"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12 mx-lg-auto mb-5 text-center">
          <div class="heading-default wow fadeInUp">
            <h2>Nos <span class="text-primary">Services</span></h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-12 mx-lg-auto">
          <div
            class="row row-cols-1 row-cols-lg-3 row-cols-md-2 g-4 wow fadeInUp hm-serv-content"
          >
            <div class="col" v-for="item in services" :key="item.id">
              <div class="theme-item">
                <div class="theme-item-overlay">
                  <img
                    src="wp-content/themes/cozipress-pro/assets/images/services/img02.jpg"
                  />
                </div>

                <div class="theme-icon" v-html="item.fa_icon"></div>

                <div class="theme-content">
                  <h4 class="theme-title">
                    <a @click.prevent="goto(item)" href="">{{ item.title }}</a>
                  </h4>

                  <p>
                    {{ item.resume }}
                  </p>

                  <a
                    @click.prevent="goto(item)"
                    class="btn btn-primary btn-like-icon"
                    >En savoir plus

                    <span class="bticn"><i class="fa fa-arrow-right"></i></span
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lg-shape1">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape1.png"
        alt="image"
      />
    </div>
    <div class="lg-shape2">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape2.png"
        alt="image"
      />
    </div>
    <div class="lg-shape3">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape3.png"
        alt="image"
      />
    </div>
    <div class="lg-shape4">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape4.png"
        alt="image"
      />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return { services: [], loading: true };
  },
  methods: {
    goto(item) {
      this.$router.push({
        name: "service",
        params: { item: item.link },
        query: { id: item.id },
      });
    },
    getservice() {
      this.loading = true;
      this.$axios.get("allservices").then((res) => {
        this.services = res.data.data;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getservice();
  },
};
</script>

<style></style>
