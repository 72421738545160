<template>
  <header id="main-header" class="main-header">
    <!--===// Start: Header Above
        =================================-->
    <div
      id="above-header"
      class="above-header d-lg-block d-none wow fadeInDown"
    >
      <div class="header-widget d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-12 mb-lg-0 mb-4">
              <div class="widget-left text-lg-left text-center">
                <aside
                  class="widget widget-contact second"
                  style="display: flex"
                  v-if="!loadingInfo"
                >
                  <div class="contact-area" v-if="coordinate.phone">
                    <div class="contact-icon">
                      <div class="contact-corn">
                        <i class="fa fa-phone"></i>
                      </div>
                    </div>
                    <div class="contact-info">
                      <p class="text">
                        <a :href="'tel:' + coordinate.phone">{{
                          coordinate.phone
                        }}</a>
                      </p>
                    </div>
                  </div>
                  <div class="contact-area" v-if="coordinate.email">
                    <div class="contact-icon">
                      <div class="contact-corn">
                        <i aria-hidden="true" class="fa fa-envelope-o"></i>
                      </div>
                    </div>
                    <div class="contact-info">
                      <p class="text">
                        <a :href="'email:' + coordinate.email">{{
                          coordinate.email
                        }}</a>
                      </p>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
            <div class="col-lg-6 col-12 mb-lg-0 mb-4">
              <div
                class="widget-right justify-content-lg-end justify-content-center text-lg-right text-center"
              >
                <aside class="widget widget_social_widget third">
                  <ul v-if="!loadingInfo">
                    <li v-if="social.facebook">
                      <a :href="social.facebook" target="_blank"
                        ><i class="fa fa-facebook"></i
                      ></a>
                    </li>
                    <li v-if="social.twitter">
                      <a :href="social.twitter" target="_blank"
                        ><i class="fa fa-twitter"></i
                      ></a>
                    </li>
                    <li v-if="social.instagram">
                      <a :href="social.instagram" target="_blank"
                        ><i class="fa fa-instagram"></i
                      ></a>
                    </li>
                    <li v-if="social.pinterest">
                      <a :href="social.pinterest" target="_blank"
                        ><i class="fa fa-pinterest"></i
                      ></a>
                    </li>
                    <li v-if="social.linkedin">
                      <a :href="social.linkedin" target="_blank"
                        ><i class="fa fa-linkedin"></i
                      ></a>
                    </li>
                  </ul>
                </aside>
                <aside class="widget textwidget btn">
                  <router-link
                    title="Simuler mon salaire"
                    to="simulation-de-la-remuneration"
                    class="btn btn-primary btn-like-icon"
                    >Simuler mon salaire
                    <span class="bticn"><i class="fa fa-calculator"></i></span
                  ></router-link>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--===// End: Header Top
        =================================-->
    <!--===// Start: Navigation Wrapper
        =================================-->
    <div class="navigation-wrapper">
      <!--===// Start: Main Desktop Navigation
            =================================-->
      <div class="main-navigation-area d-none d-lg-block">
        <div
          :class="
            !isscrolling
              ? ' main-navigation is-sticky-on'
              : ' main-navigation is-sticky-on is-sticky-menu'
          "
        >
          <div class="container">
            <div class="row">
              <div class="col-3 my-auto">
                <div class="logo">
                  <router-link
                    to="/"
                    class="custom-logo-link"
                    rel="home"
                    aria-current="page"
                    ><img
                      width="218"
                      height="187"
                      src="wp-content/uploads/2021/04/Logo-Portalite-Rogne.png"
                      class="custom-logo"
                      alt="Portalite | Portage Salarial"
                  /></router-link>
                </div>
              </div>
              <div class="col-9 my-auto">
                <nav class="navbar-area">
                  <div class="main-navbar">
                    <ul id="menu-menu-1" class="main-menu">
                      <li
                        itemscope="itemscope"
                        itemtype="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-770"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-764 current_page_item menu-item-has-children dropdown active menu-item-770 nav-item"
                      >
                        <router-link
                          title="Portalite"
                          to="/"
                          class="nav-link"
                          aria-current="page"
                          >Portalite</router-link
                        >
                        <span class="mobile-collapsed d-lg-none"
                          ><button
                            type="button"
                            class="fa fa-chevron-right"
                            aria-label="Mobile Collapsed"
                          ></button
                        ></span>
                        <ul class="dropdown-menu" role="menu">
                          <li
                            itemscope="itemscope"
                            itemtype="https://www.schema.org/SiteNavigationElement"
                            id="menu-item-26"
                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26 nav-item"
                          >
                            <router-link
                              title="A propos
																	de Nous"
                              to="about"
                              class="dropdown-item"
                              >A propos de Nous</router-link
                            >
                          </li>
                          <li
                            itemscope="itemscope"
                            itemtype="https://www.schema.org/SiteNavigationElement"
                            id="menu-item-120"
                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-120 nav-item"
                          >
                            <router-link
                              title="Nos
																	services"
                              to="nos-services"
                              class="dropdown-item"
                              >Nos services</router-link
                            >
                          </li>
                        </ul>
                      </li>

                      <!---->
                      <li
                        itemscope="itemscope"
                        itemtype="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-336"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-336 nav-item"
                        v-for="item in navigations"
                        :key="item.id"
                      >
                        <router-link
                          :title="item.nav"
                          :to="{
                            name: 'navigation',
                            params: { item: item.name },
                          }"
                          class="nav-link"
                          >{{ item.nav }}</router-link
                        >
                        <span class="mobile-collapsed d-lg-none"
                          ><button
                            type="button"
                            class="fa fa-chevron-right"
                            aria-label="Mobile Collapsed"
                            v-if="item.sousnavigation.length > 0"
                          ></button
                        ></span>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="mobile-collapsed
															d-lg-none&#039;&gt;&lt;button type=&#039;button&#039;
															class=&#039;fa fa-chevron-right&#039; aria-label=&#039;Mobile
															Collapsed&#039;&gt;&lt;/button&gt;&lt;/span&gt;&lt;ul class="
                          role="menu"
                          v-if="item.sousnavigation.length > 0"
                        >
                          <li
                            itemscope="itemscope"
                            itemtype="https://www.schema.org/SiteNavigationElement"
                            id="menu-item-339"
                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-339 nav-item"
                            v-for="it in item.sousnavigation"
                            :key="it.id"
                          >
                            <router-link
                              :title="it.sousnav"
                              :to="{
                                name: 'navigation',
                                params: { item: it.name },
                              }"
                              class="dropdown-item"
                              >{{ it.sousnav }}</router-link
                            >
                          </li>
                        </ul>
                      </li>
                      <li
                        itemscope="itemscope"
                        itemtype="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-79"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-79 nav-item"
                      >
                        <router-link
                          title="Contact"
                          to="contact"
                          class="nav-link"
                          >Contact</router-link
                        >
                      </li>
                      <li
                        itemscope="itemscope"
                        itemtype="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-438"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-438 nav-item"
                      >
                        <a
                          title="Espace
															Adhérent"
                          href="https://www.hoggo.com/espace-salarie/sign_in"
                          class="nav-link"
                          >Espace Adhérent</a
                        >
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--===// End:  Main Desktop Navigation
            =================================-->
      <!--===// Start: Main Mobile Navigation
            =================================-->
      <div class="main-mobile-nav is-sticky-on">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="main-mobile-menu">
                <div class="mobile-logo">
                  <div class="logo">
                    <router-link
                      to="/"
                      class="custom-logo-link"
                      rel="home"
                      aria-current="page"
                      ><img
                        width="218"
                        height="187"
                        src="wp-content/uploads/2021/04/Logo-Portalite-Rogne.png"
                        class="custom-logo"
                        alt="Portalite | Portage Salarial"
                    /></router-link>
                    <p class="site-description">
                      Site en cours de construction
                    </p>
                  </div>
                </div>
                <div class="menu-collapse-wrap">
                  <div class="hamburger-menu">
                    <button
                      type="button"
                      class="menu-collapsed collapsed"
                      aria-label="MenuCollaped"
                      @click="toggleMenu()"
                    >
                      <div class="top-bun"></div>
                      <div class="meat"></div>
                      <div class="bottom-bun"></div>
                    </button>
                  </div>
                </div>
                <div
                  class="main-mobile-wrapper"
                  :class="{ 'main-mobile-build-active': navshow }"
                >
                  <div
                    id="mobile-menu-build"
                    class="main-mobile-build"
                    :class="{
                      'main-mobile-build-active': navshow,
                      'main-mobile-build-active-top': navshow,
                    }"
                  >
                    <button
                      type="button"
                      class="header-close-menu close-style"
                      aria-label="Header Close Menu"
                      @click="toggleMenu()"
                    ></button>
                    <ul id="menu-menu-1" class="main-menu">
                      <li
                        itemscope="itemscope"
                        itemtype="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-770"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-764 current_page_item menu-item-has-children dropdown active menu-item-770 nav-item"
                      >
                        <router-link
                          to="/"
                          class="nav-link"
                          rel="home"
                          aria-current="page"
                          >Portalite</router-link
                        >
                        <span class="mobile-collapsed d-lg-none"
                          ><button
                            type="button"
                            class="fa fa-chevron-right navbar-toggler"
                            aria-label="Mobile Collapsed"
                            id="01"
                            @click="showSousLink"
                          ></button
                        ></span>
                        <ul
                          class="dropdown-menu"
                          role="menu"
                          style="display: none"
                          id="sous_link01"
                        >
                          <li
                            itemscope="itemscope"
                            itemtype="https://www.schema.org/SiteNavigationElement"
                            id="menu-item-26"
                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26 nav-item"
                          >
                            <router-link
                              title="A propos
																	de Nous"
                              to="about"
                              class="dropdown-item"
                              >A propos de Nous</router-link
                            >
                          </li>
                          <li
                            itemscope="itemscope"
                            itemtype="https://www.schema.org/SiteNavigationElement"
                            id="menu-item-120"
                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-120 nav-item"
                          >
                            <router-link
                              title="Nos
																	services"
                              to="nos-services"
                              class="dropdown-item"
                              >Nos services</router-link
                            >
                          </li>
                        </ul>
                      </li>
                      <li
                        itemscope="itemscope"
                        itemtype="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-118"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-118 nav-item"
                        v-for="item in navigations"
                        :key="item.id"
                      >
                        <router-link
                          v-if="item.name == 'simulation-de-la-remuneration'"
                          title="Je suis consultant"
                          class="nav-link"
                          to="simulation-de-la-remuneration"
                          >{{ item.nav }}</router-link
                        >
                        <router-link
                          v-else
                          :title="item.nav"
                          :to="{
                            name: 'navigation',
                            params: { item: item.name },
                          }"
                          class="nav-link"
                          >{{ item.nav }}</router-link
                        >
                        <span class="mobile-collapsed d-lg-none"
                          ><button
                            type="button"
                            class="fa fa-chevron-right"
                            aria-label="Mobile Collapsed"
                            :id="item.id"
                            @click="showSousLink"
                          ></button
                        ></span>
                        <ul
                          class="dropdown-menu"
                          :id="`sous_link${item.id}`"
                          role="menu"
                          style="display: none"
                          v-if="item.sousnavigation.length > 0"
                        >
                          <li
                            itemscope="itemscope"
                            itemtype="https://www.schema.org/SiteNavigationElement"
                            id="menu-item-135"
                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-135 nav-item"
                            v-for="it in item.sousnavigation"
                            :key="it.id"
                          >
                            <router-link
                              :title="it.sousnav"
                              :to="{
                                name: 'navigation',
                                params: { item: it.name },
                              }"
                              class="dropdown-item"
                              >{{ it.sousnav }}</router-link
                            >
                          </li>
                        </ul>
                      </li>

                      <li
                        itemscope="itemscope"
                        itemtype="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-79"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-79 nav-item"
                      >
                        <router-link
                          title="Contact"
                          to="contact"
                          class="nav-link"
                          >Contact</router-link
                        >
                      </li>
                      <li
                        itemscope="itemscope"
                        itemtype="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-438"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-438 nav-item"
                      >
                        <a
                          title="Espace Adhérent"
                          href="https://www.portalite.fr/espace-client/"
                          class="nav-link"
                          >Espace Adhérent</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="header-above-btn">
                  <button
                    type="button"
                    class="header-above-collapse"
                    aria-label="Header Above Collapse"
                    @click.prevent="showInfo = !showInfo"
                  >
                    <span></span>
                  </button>
                </div>
                <div
                  :class="
                    showInfo
                      ? 'header-above-wrapper is-active'
                      : 'header-above-wrapper'
                  "
                >
                  <div id="header-above-bar" class="header-above-bar">
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-6 col-12 mb-lg-0 mb-4">
                          <div class="widget-left text-lg-left text-center">
                            <aside
                              class="widget widget-contact second"
                              v-if="!loadingInfo"
                            >
                              <div class="contact-area">
                                <div class="contact-icon">
                                  <div
                                    class="contact-corn"
                                    v-if="coordinate.phone"
                                  >
                                    <i class="fa fa-phone"></i>
                                  </div>
                                </div>
                                <div class="contact-info">
                                  <h6 class="title">Appellez Nous</h6>
                                  <p class="text">
                                    <a :href="'tel:' + coordinate.phone">{{
                                      coordinate.phone
                                    }}</a>
                                  </p>
                                </div>
                              </div>
                            </aside>
                          </div>
                        </div>
                        <div class="col-lg-6 col-12 mb-lg-0 mb-4">
                          <div
                            class="widget-right justify-content-lg-end justify-content-center text-lg-right text-center"
                          >
                            <aside class="widget widget_social_widget third">
                              <ul v-if="!loadingInfo">
                                <li v-if="social.facebook">
                                  <a :href="social.facebook" target="_blank"
                                    ><i class="fa fa-facebook"></i
                                  ></a>
                                </li>
                                <li v-if="social.twitter">
                                  <a :href="social.twitter" target="_blank"
                                    ><i class="fa fa-twitter"></i
                                  ></a>
                                </li>
                                <li v-if="social.instagram">
                                  <a :href="social.instagram" target="_blank"
                                    ><i class="fa fa-instagram"></i
                                  ></a>
                                </li>
                                <li v-if="social.pinterest">
                                  <a :href="social.pinterest" target="_blank"
                                    ><i class="fa fa-pinterest"></i
                                  ></a>
                                </li>
                                <li v-if="social.linkedin">
                                  <a :href="social.linkedin" target="_blank"
                                    ><i class="fa fa-linkedin"></i
                                  ></a>
                                </li>
                              </ul>
                            </aside>
                            <aside class="widget textwidget btn">
                              <router-link
                                title="Simuler mon salaire"
                                to="simulation-de-la-remuneration"
                                class="btn btn-primary btn-like-icon"
                                >Simuler mon salaire
                                <span class="bticn"
                                  ><i class="fa fa-calculator"></i></span
                              ></router-link>
                            </aside>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--===// End: Main Mobile Navigation
            =================================-->
    </div>
    <!--===// End: Navigation Wrapper
        =================================-->
  </header>
</template>

<script>
export default {
  data() {
    return {
      navshow: false,
      coordinate: [],
      navigations: null,
      social: null,
      loading: true,
      loadingInfo: true,
      showInfo: false,
      isscrolling: false,
    };
  },
  methods: {
    showSousLink(event) {
      console.log(event.target.id);
      var target = event.target;
      target.classList.toggle("fa-chevron-right");
      target.classList.toggle("fa-chevron-down");
      var id = event.target.id;
      var sous_link = document.getElementById("sous_link" + id);
      sous_link.classList.toggle("displayblock");
    },
    toggleMenu() {
      this.navshow = !this.navshow;
    },
    getNavigations() {
      this.loading = true;
      this.$axios.get("allnavigation").then((res) => {
        this.navigations = res.data.data;

        this.loading = false;
      });
    },
    getSocial() {
      this.loading = true;
      this.$axios.get("allsocial").then((res) => {
        this.social = res.data.data[0];
        this.loading = false;
      });
      this.loadingInfo = true;
      this.$axios.get("allcoordinate").then((res) => {
        this.coordinate = res.data.data[0];
        this.loadingInfo = false;
      });
    },
    handleScroll() {
      console.log(window.scrollY);
      console.log(window.scrollY > 100);
      if (window.scrollY > 100) this.isscrolling = true;
      else this.isscrolling = false;
    },
  },
  mounted() {
    this.getNavigations();
    this.getSocial();
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
.contact-area {
  padding-left: 20px;
}
.main-mobile-build-active {
  visibility: visible !important;
  opacity: 1 !important;
}

.main-mobile-build-active-top {
  top: 100% !important;
}
.displayblock {
  display: block !important;
}
</style>
