<template>
  <section
    id="testimonials-section"
    class="home-testimonial testimonials-section st-py-default shapes-section"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12 mx-lg-auto mb-5 text-center">
          <div class="heading-default text-white wow fadeInUp">
            <h2>Ils ont choisi <span class="text-primary">PORTALITE</span></h2>
          </div>
        </div>
      </div>

      <div class="row wow fadeInUp">
        <carousel
          class="col-12 testimonials-slider owl-carousel owl-theme"
          :autoplay="true"
          :nav="false"
          :margin="50"
          :responsive="{
            1200: { items: 3 },
            600: { items: 1 },
            0: { items: 1 },
          }"
        >
          <div class="testimonials-item shapes-section">
            <div
              class="testimonials-content d-flex flex-column justify-content-center"
            >
              <p>
                Portalite est une petite structure très dynamique qui a su
                m&#039;accompagner rapidement dans mon activité. Je suis très
                satisfait.
              </p>

              <div class="rating-star">
                <a href="#"><i class="fa fa-star"></i></a>

                <a href="#"><i class="fa fa-star"></i></a>

                <a href="#"><i class="fa fa-star"></i></a>

                <a href="#"><i class="fa fa-star"></i></a>

                <a href="#"><i class="fa fa-star"></i></a>
              </div>
              <div class="testimonials-title">
                <h4>André Pavet</h4>

                <span class="text-primary">Chef de projet Télécom</span>
              </div>
            </div>
            <div class="testimonials-client"></div>
            <div class="lg-shape32">
              <img
                src="wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape3.png"
                alt="image"
              />
            </div>
            <div class="lg-shape33">
              <img
                src="wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape4.png"
                alt="image"
              />
            </div>
            <div class="lg-shape34">
              <img
                src="wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape5.png"
                alt="image"
              />
            </div>
            <div class="lg-shape35">
              <img
                src="wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape6.png"
                alt="image"
              />
            </div>
          </div>
          <div class="testimonials-item shapes-section">
            <div
              class="testimonials-content d-flex flex-column justify-content-center"
            >
              <p>
                Après un bechmark des sociétés de portage je me suis rendu à
                l&#039;évidence que Portalite propose la meilleure offre.
                Aujourd&#039;hui je suis très content de mon choix
              </p>

              <div class="rating-star">
                <a href="#"><i class="fa fa-star"></i></a>

                <a href="#"><i class="fa fa-star"></i></a>

                <a href="#"><i class="fa fa-star"></i></a>

                <a href="#"><i class="fa fa-star"></i></a>

                <a href="#"><i class="fa fa-star"></i></a>
              </div>
              <div class="testimonials-title">
                <h4>Amine Madir</h4>

                <span class="text-primary">Consultant Big Data</span>
              </div>
            </div>
            <div class="testimonials-client"></div>
            <div class="lg-shape32">
              <img
                src="wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape3.png"
                alt="image"
              />
            </div>
            <div class="lg-shape33">
              <img
                src="wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape4.png"
                alt="image"
              />
            </div>
            <div class="lg-shape34">
              <img
                src="wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape5.png"
                alt="image"
              />
            </div>
            <div class="lg-shape35">
              <img
                src="wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape6.png"
                alt="image"
              />
            </div>
          </div>
          <div class="testimonials-item shapes-section">
            <div
              class="testimonials-content d-flex flex-column justify-content-center"
            >
              <p>
                Je n&#039;avais ni le temps ni la motivation de monter une
                structure pour mon activité. J&#039;ai opté pour le portage
                comme solution très optimale et mon choix est porté sur
                Portalite pour la qualité d&#039;écoute et des explications
                ainsi que le rapport qualité prix
              </p>

              <div class="rating-star">
                <a href="#"><i class="fa fa-star"></i></a>

                <a href="#"><i class="fa fa-star"></i></a>

                <a href="#"><i class="fa fa-star"></i></a>

                <a href="#"><i class="fa fa-star"></i></a>

                <a href="#"><i class="fa fa-star"></i></a>
              </div>
              <div class="testimonials-title">
                <h4>Amandine Lecerf</h4>

                <span class="text-primary">Consultante en stratégie </span>
              </div>
            </div>
            <div class="testimonials-client"></div>
            <div class="lg-shape32">
              <img
                src="wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape3.png"
                alt="image"
              />
            </div>
            <div class="lg-shape33">
              <img
                src="wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape4.png"
                alt="image"
              />
            </div>
            <div class="lg-shape34">
              <img
                src="wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape5.png"
                alt="image"
              />
            </div>
            <div class="lg-shape35">
              <img
                src="wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape6.png"
                alt="image"
              />
            </div>
          </div>
          <div class="testimonials-item shapes-section">
            <div
              class="testimonials-content d-flex flex-column justify-content-center"
            >
              <p>
                la relation humaine qu&#039;apporte Portalite fait en sorte
                qu&#039;on ne peut qu&#039;à adhérer
              </p>

              <div class="rating-star">
                <a href="#"><i class="fa fa-star"></i></a>

                <a href="#"><i class="fa fa-star"></i></a>

                <a href="#"><i class="fa fa-star"></i></a>

                <a href="#"><i class="fa fa-star"></i></a>

                <a href="#"><i class="fa fa-star"></i></a>
              </div>
              <div class="testimonials-title">
                <h4>Marwen Rhif</h4>

                <span class="text-primary"
                  >Consultant en recrutement specialisé</span
                >
              </div>
            </div>
            <div class="testimonials-client"></div>
            <div class="lg-shape32">
              <img
                src="wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape3.png"
                alt="image"
              />
            </div>
            <div class="lg-shape33">
              <img
                src="wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape4.png"
                alt="image"
              />
            </div>
            <div class="lg-shape34">
              <img
                src="wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape5.png"
                alt="image"
              />
            </div>
            <div class="lg-shape35">
              <img
                src="wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape6.png"
                alt="image"
              />
            </div>
          </div>
        </carousel>
      </div>
    </div>
    <div class="lg-shape21">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape1.png"
        alt="image"
      />
    </div>
    <div class="lg-shape21bottom">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape2.png"
        alt="image"
      />
    </div>
  </section>
</template>
<style scoped>
.testimonials-item {
  height: 450px;
}
.testimonials-content {
  height: 100%;
}
</style>
<script>
import carousel from "vue-owl-carousel";
export default {
  components: { carousel },
};
</script>

<style scoped>
#testimonials-section {
  background: url("/wp-content/themes/cozipress-pro/assets/images/testimonials/testimonial_bg.jpg")
    no-repeat fixed center / cover rgba(0, 0, 0, 0.85);
  background-blend-mode: multiply;
}
</style>
