<template>
  <section
    id="projects-section"
    class="project-home projects-section shapes-section st-pb-default"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12 mx-lg-auto mb-5 text-center">
          <div class="heading-default wow fadeInUp"></div>
        </div>
      </div>
      <div class="st-filter-wrapper-2">
        <div class="st-tab-filter text-center"></div>
        <div
          id="st-filter-init-2"
          class="row row-cols-1 row-cols-lg-4 row-cols-md-2 st-filter-init g-4 wow fadeInUp"
        ></div>
      </div>
    </div>
    <div class="lg-shape11">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape11.png"
        alt="image"
      />
    </div>
    <div class="lg-shape10">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape10.png"
        alt="image"
      />
    </div>
    <div class="lg-shape16">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape16.png"
        alt="image"
      />
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
