<template>
  <section
    id="gallery-section"
    class="gallery-section gallery-homepage shapes-section st-pt-default"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12 mx-lg-auto mb-5 text-center">
          <div class="heading-default wow fadeInUp"></div>
        </div>
      </div>
    </div>
    <div class="container-fluid p-0">
      <div
        class="row row-cols-1 row-cols-lg-1 row-cols-md-2 g-0 wow fadeInUp gallery-hm-wrap"
      >
        <div class="col">
          <figure class="gallery-item">
            <div class="gallery-icon landscape">
              <div class="image"></div>

              <div class="gallery-popup-icon mgf-popup">
                <a href="#" class="image-gallery-link zoom-icon"
                  ><i class="fa fa-arrows-alt"></i
                ></a>
              </div>
            </div>
            <figcaption class="wp-caption-text gallery-caption"></figcaption>
          </figure>
        </div>
      </div>
    </div>
    <div class="lg-shape10">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape10.png"
        alt="image"
      />
    </div>
    <div class="lg-shape11">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape11.png"
        alt="image"
      />
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
