<template>
  <section id="post-section" class="home-blog post-section st-pb-default">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12 mx-lg-auto mb-5 text-center">
          <div class="heading-default wow fadeInUp"></div>
        </div>
      </div>
      <div class="row row-cols-1 wow fadeInUp">
        <div class="col-12">
          <div class="blog-carousel owl-carousel owl-theme"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
