<template>
  <section
    id="team-section"
    class="team-home team-section st-pb-default shapes-section"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12 mx-lg-auto mb-5 text-center">
          <div class="heading-default wow fadeInUp">
            <span class="badge ttl">.</span>

            <h2>Notre <span class="text-primary">équipe</span></h2>
          </div>
        </div>
      </div>
      <div
        class="row row-cols-1 row-cols-lg-4 row-cols-md-2 g-4 wow fadeInUp team-data"
      >
        <div class="col" v-for="item in teams" :key="item.key">
          <div class="our-team">
            <div class="team-img">
              <img :src="item.image" :alt="item.name" :title="item.name" />
            </div>
            <div class="team-thumb-img">
              <img :src="item.image" :alt="item.name" :title="item.name" />
            </div>
            <div class="team-info">
              <div class="team-heading">
                <h4>
                  <a href="javascript:void(0);">{{ item.name }}</a>
                </h4>
                <p class="text-primary mb-0">{{ item.poste }}</p>
              </div>
              <div class="team-widget-wrap mt-4">
                <aside class="widget widget_social_widget">
                  <ul>
                    <li>
                      <a href="http://facebook.com/"
                        ><i class="fa fa-facebook"></i
                      ></a>
                    </li>
                    <li>
                      <a href="http://twitter.com/"
                        ><i class="fa fa-twitter"></i
                      ></a>
                    </li>
                    <li>
                      <a href="http://instagram.com/"
                        ><i class="fa fa-instagram"></i
                      ></a>
                    </li>
                    <li>
                      <a href="http://pinterest.com/"
                        ><i class="fa fa-pinterest"></i
                      ></a>
                    </li>
                  </ul>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lg-shape17">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape17.png"
        alt="image"
      />
    </div>
    <div class="lg-shape18">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape18.png"
        alt="image"
      />
    </div>
    <div class="lg-shape19">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape19.png"
        alt="image"
      />
    </div>
    <div class="lg-shape20">
      <img
        src="wp-content/themes/cozipress-pro/assets/images/clipArt/shape20.png"
        alt="image"
      />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return { teams: [], loading: true };
  },
  methods: {
    getTeam() {
      this.loading = true;
      this.$axios.get("allteam").then((res) => {
        this.teams = res.data.data;
        console.log(this.teams);
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getTeam();
  },
};
</script>

<style></style>
